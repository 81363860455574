import $ from 'jquery'
import gsap from 'gsap'
import throttle from 'lodash.throttle'

// Constants
const ANIMATION_DURATION = 0.1
const HEADER_BACKGROUND_OFFSET = 120
const MENU_OPENED_CLASS = 'mobile-menu--opened'
const MENU_BUTTON_OPENED_CLASS = 'menu-button--opened'
const SOCIAL_MEDIA_MENU_OPENED_CLASS = 'menu__social-media--opened'
const MENU_CONTAINER_BACKGROUND_CLASS = 'menu-container--background'
const CONTACT_US_HIDE_CLASS = 'hidden'
const CONTACT_BUTTON_OFFSET = 500

// Element Selectors
const closeMenuButton = $('[data-id="close-menu-btn"]')
const menu = $('[data-id="mobile-menu"]')
const menuButton = $('[data-id="menu-button"]')
const menuHeader = $('[data-id="menu-header"]')
const menuLinks = $('[data-link]')
const socialMedias = $('[data-id="social-medias"]')
const contactButton = $('[data-id="contact-us"]')

// Animation
const showMenuAnimation = gsap
  .timeline({
    onStart: () => {
      menu.addClass(MENU_OPENED_CLASS)
    },
    onReverseComplete: () => {
      menu.removeClass(MENU_OPENED_CLASS)
    },
  })
  .fromTo(
    menu,
    { opacity: 0, x: 400 },
    { opacity: 1, x: 0, duration: ANIMATION_DURATION, ease: 'none' }
  )

showMenuAnimation.reverse()

// Menu
const isMenuOpen = () => menu.hasClass(MENU_OPENED_CLASS)

const handleMenuOpening = () => {
  if (!isMenuOpen()) openMenu()
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

const closeMenu = () => {
  menuButton.removeClass(MENU_BUTTON_OPENED_CLASS)
  socialMedias.removeClass(SOCIAL_MEDIA_MENU_OPENED_CLASS)
  showMenuAnimation.reverse()
}

const openMenu = () => {
  menuButton.addClass(MENU_BUTTON_OPENED_CLASS)
  socialMedias.addClass(SOCIAL_MEDIA_MENU_OPENED_CLASS)
  showMenuAnimation.restart()
}

menu.on('click', (e) => e.stopPropagation())
closeMenuButton.on('click', closeMenu)
menuLinks.on('click', closeMenu)
menuButton.on('click', handleMenuOpening)

// Scroll

const updateOnScroll = () => {
  updateMenuBackground()
  updateContactButton()
}

const updateContactButton = () => {

  const scrollBottom = $(document).height() - $(window).height() - $(window).scrollTop();
  const isBelowOffset = scrollBottom <= CONTACT_BUTTON_OFFSET
  const isHiding = contactButton.hasClass(CONTACT_US_HIDE_CLASS)

  if (isBelowOffset && !isHiding) return hideContactButton()
  if (!isBelowOffset && isHiding) return showContactButton()
}
const updateMenuBackground = () => {
  const isBelowOffset = $(window).scrollTop() >= HEADER_BACKGROUND_OFFSET
  const isShowing = menuHeader.hasClass(MENU_CONTAINER_BACKGROUND_CLASS)

  if (isBelowOffset && !isShowing) return addMenuBackground()
  if (!isBelowOffset && isShowing) return removeMenuBackground()
}

const addMenuBackground = () => menuHeader.addClass(MENU_CONTAINER_BACKGROUND_CLASS)

const removeMenuBackground = () => menuHeader.removeClass(MENU_CONTAINER_BACKGROUND_CLASS)

const hideContactButton = () => contactButton.addClass(CONTACT_US_HIDE_CLASS)

const showContactButton = () => contactButton.removeClass(CONTACT_US_HIDE_CLASS)

$(document).on('ready', updateOnScroll)
$(window).on('scroll', throttle(updateOnScroll, 200))
$(window).on('keydown', ({ key }) => {
  if (key === 'Escape') {
    closeMenu()
  }
})
